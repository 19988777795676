import React from "react"
import styled from "@emotion/styled"
import { Logo } from "./shared/Logo"

const NoImageComponent = () => {
  return (
    <NoImage>
      <Logo />
    </NoImage>
  )
}

export default NoImageComponent

export const NoImage = styled.div`
  height: 235px;
  background-color: transparent;
  border-bottom: 1px solid #f7f7f7;

  .gatsby-image-wrapper {
    width: 270px;
    margin: 0 auto;
    margin-top: 80px;
  }
`
