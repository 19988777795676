import React, { useState } from "react";
import { graphql } from "gatsby";
import { pathOr } from "ramda";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Wrapper } from "../components/Wrapper";
import { PostCard } from "../components/post/PostCard";
import { Posts, PostFeed, Button } from "../styles/shared";
import { css } from "@emotion/core";
import { Row } from "./kontakt";
// import Helmet from "react-helmet"

const IndexPage = ({ data }) => {
  const latestPost = pathOr(null, ["latestPost", "edges"], data)[0];
  const otherPosts = pathOr(null, ["otherPosts", "edges"], data);

  const [showedPosts, setShowedPosts] = useState(otherPosts.slice(0, 4));
  const isMorePosts = showedPosts.length < otherPosts.length;
  let sliceForMoreNumber = showedPosts.length + 4;

  const handleLoadMore = () => {
    if (sliceForMoreNumber > otherPosts.length) {
      sliceForMoreNumber = otherPosts.length;
    }

    setShowedPosts(otherPosts.slice(otherPosts.slice(0, sliceForMoreNumber)));
  };

  return (
    <Layout isHome>
      {/* <Helmet>
      TODO: insert helmet meta tags
    </Helmet> */}
      <SEO title="Naslovna" />
      <Wrapper>
        <div css={[container, Posts]}>
          <PostCard key={latestPost.node.id} post={latestPost.node} large />
          <h2>Ostale novosti kluba</h2>
          <div css={[PostFeed]}>
            {showedPosts.map(post => {
              return <PostCard key={post.node.id} post={post.node} />;
            })}
          </div>
          <Row css={css({ justifyContent: "center" })}>
            {isMorePosts && <Button onClick={handleLoadMore}>Učitaj još</Button>}
          </Row>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    latestPost: allWordpressPost(sort: { order: DESC, fields: date }, limit: 1) {
      edges {
        node {
          id
          date
          slug
          link
          title
          categories {
            name
          }
          featured_media {
            source_url
            localFile {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          author {
            id
            name
          }
          excerpt
        }
      }
    }
    otherPosts: allWordpressPost(sort: { order: DESC, fields: date }, skip: 1) {
      edges {
        node {
          id
          date
          slug
          link
          title
          categories {
            name
          }
          featured_media {
            source_url
            localFile {
              childImageSharp {
                fluid(maxWidth: 900, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          author {
            id
            name
          }
          excerpt
        }
      }
    }
  }
`;

const container = css`
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
`;
