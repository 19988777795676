import { format } from "date-fns";
import { hr } from "date-fns/esm/locale";
import { Link } from "gatsby";
import { lighten } from "polished";
import React from "react";
import parse from "html-react-parser";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import Img from "gatsby-image/withIEPolyfill";
import { colors } from "../../styles/colors";
import { getImage } from "../../helpers/pathExtractor";
import NoImageComponent from "../no-image";

export const PostCard = ({ post, large = false }) => {
  const date = new Date(post.date);
  // 2018-08-20
  const datetime = format(date, "yyyy-MM-dd", { locale: hr });
  // 20 AUG 2018
  const displayDatetime = format(date, "dd LLL yyyy", { locale: hr });

  const fluid = getImage(post.featured_media, "fluid");
  const isWithImage = post.featured_media;

  return (
    <article
      className={`post-card ${post.featured_media ? "" : "no-image"} ${
        large ? "post-card-large" : "with-shadow"
      }`}
      css={[PostCardStyles, large && PostCardLarge]}
    >
      {isWithImage ? (
        <Link className="post-card-image-link" css={PostCardImageLink} to={post.slug}>
          <PostCardImage className="post-card-image">
            <Img fluid={fluid} />
          </PostCardImage>
        </Link>
      ) : (
        <NoImageComponent />
      )}
      <PostCardContent className="post-card-content">
        <Link className="post-card-content-link" css={PostCardContentLink} to={post.slug}>
          <PostCardHeader className="post-card-header">
            {post.categories.name && (
              <PostCardPrimaryTag className="post-card-primary-tag">
                {post.categories[0].name}
              </PostCardPrimaryTag>
            )}
            <PostCardTitle className="post-card-title">{post.title}</PostCardTitle>
          </PostCardHeader>
          <PostCardExcerpt className="post-card-excerpt">{parse(post.excerpt)}</PostCardExcerpt>
        </Link>
        <PostCardMeta className="post-card-meta">
          <PostCardBylineContent className="post-card-byline-content">
            <span>{post.author.name}</span>
            <span className="post-card-byline-date">
              <time dateTime={datetime}>{displayDatetime}</time>{" "}
              <span className="bull">&bull;</span> 10 min read
            </span>
          </PostCardBylineContent>
        </PostCardMeta>
      </PostCardContent>
    </article>
  );
};

const PostCardStyles = css`
  position: relative;
  flex: 1 1 301px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 20px 40px;
  min-height: 220px;
  background-size: cover;
  background: ${colors.white};
  transition: 1s;

  &.with-shadow {
    box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.23);
  }

  &.post-card {
    max-width: 310px;
  }
`;

const PostCardLarge = css`
  max-width: 600px !important;

  @media (min-width: 795px) {
    flex: 1 1 100%;
    flex-direction: row;
    padding-bottom: 40px;
    min-height: 280px;
    background: transparent;
    justify-content: center;
    max-width: 100% !important;

    :not(.no-image) .post-card-header {
      margin-top: 0;
    }

    .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      margin-bottom: 0;
      min-height: 380px;
      max-width: 800px;
      padding: 20px;
    }

    .post-card-content {
      flex: 0 1 361px;
      justify-content: center;
    }

    .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-content-link {
      padding: 0 0 0 40px;
    }

    .post-card-meta {
      padding: 0 0 0 40px;
    }

    .post-card-excerpt p {
      margin-bottom: 1.5em;
      font-size: 1.8rem;
      line-height: 1.5em;
    }
  }
`;

const PostCardImageLink = css`
  position: relative;
  display: block;
  overflow: hidden;
`;

const PostCardImage = styled.div`
  width: auto;
  background: ${colors.lightgrey} no-repeat center center;
  background-size: cover;

  .gatsby-image-wrapper {
    min-height: 235px;
  }
`;

const PostCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PostCardContentLink = css`
  position: relative;
  display: block;
  padding: 20px;
  color: ${colors.darkgrey};

  :hover {
    text-decoration: none;
  }
`;

const PostCardPrimaryTag = styled.div`
  margin: 0 0 0.2em;
  /* color: var(--blue); */
  color: ${colors.blue};
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: uppercase;
`;

const PostCardTitle = styled.h2`
  margin: 0 0 0.4em;
  line-height: 1.15em;
  transition: color 0.2s ease-in-out;
`;

const PostCardExcerpt = styled.section`
  font-family: EuclidCircularLight;
`;

const PostCardMeta = styled.footer`
  display: flex;
  align-items: flex-start;
  padding: 10px;
`;

const PostCardBylineContent = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  margin: 4px 0 0 10px;
  /* color: color(var(--midgrey) l(+10%)); */
  color: ${colors.blue};
  font-size: 1.2rem;
  line-height: 1.4em;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  span {
    margin: 0;
  }

  a {
    /* color: color(var(--darkgrey) l(+20%)); */
    color: ${lighten("0.2", colors.darkgrey)};
    font-weight: 600;
  }
`;

const PostCardHeader = styled.header`
  margin: 15px 0 0;
`;

export const StaticAvatar = css`
  display: block;
  overflow: hidden;
  margin: 0 0 0 -6px;
  width: 34px;
  height: 34px;
  border: #fff 2px solid;
  border-radius: 100%;
`;

export const AuthorProfileImage = css`
  display: block;
  width: 100%;
  height: 100%;
  /* background: color(var(--lightgrey) l(+10%)); */
  background: ${lighten("0.1", colors.lightgrey)};
  border-radius: 100%;
  object-fit: cover;
`;
